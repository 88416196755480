import {
  DetailsList,
  getTheme,
  Label,
  Panel,
  PanelType,
  SelectionMode,
} from "@fluentui/react";
import {
  DetailsRow,
  IDetailsListProps,
  IDetailsRowStyles,
  Selection,
} from "@fluentui/react/lib/DetailsList";
import {
  getAssetAvailabilities,
  IScheduledStatus,
  ITimeslot,
} from "../../services/assetServices";
import { useEffect, useState } from "react";
import { useSetAtom } from "jotai";
import {
  errorMessageAtom,
  isInProgressAtom,
} from "../../atoms/messageBarAtoms";

export interface ITimeslotSelectorProps {
  assetId: number;
  isOpen: boolean;
  hideTimeslotSelector: any;
  onTimeslotSelected: (selectedTimeslot: ITimeslot) => void;
}

const TimeslotSelector = (props: ITimeslotSelectorProps) => {
  const [Timeslots, setTimeslots] = useState<ITimeslot[]>([]);
  const [statusChanges, setStatusChanges] = useState<IScheduledStatus[]>([]);

  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const fetchData = async () => {
    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      const data = await getAssetAvailabilities(
        abortController,
        props.assetId,
        1
      );
      setTimeslots(data.timeslots);
      setStatusChanges(data.scheduledStatuses);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    if (props.assetId > 0) {
      fetchData();
    }
  }, []);

  const _selection = new Selection({
    onSelectionChanged: () => {
      if (props.onTimeslotSelected) {
        if (_selection.getSelectedCount() > 0) {
          props.onTimeslotSelected(_selection.getSelection()[0] as ITimeslot);
        }
      }
    },
  });

  const theme = getTheme();

  const onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.item.rentCode !== null) {
        customStyles.root = {
          backgroundColor: theme.palette.themeLighterAlt,
          color: theme.palette.neutralQuaternary,
        };
      }
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideTimeslotSelector}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText="Pick Timeslot for selected work order"
    >
      <Label>Scheduled status changes</Label>
      <DetailsList
        columns={[
          {
            name: "Status",
            fieldName: "statusName",
            key: "StatusName",
            minWidth: 100,
          },
          {
            key: "StartTime",
            name: "Start Time",
            fieldName: "startTime",
            minWidth: 100,
            maxWidth: 125,
            isResizable: true,
          },
          {
            key: "EndTime",
            name: "End Time",
            fieldName: "endTime",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
          },
        ]}
        items={statusChanges}
      />
      <Label>Time slots</Label>
      <DetailsList
        columns={[
          {
            key: "RentCode",
            name: "Rent Code",
            fieldName: "rentCode",
            minWidth: 100,
            maxWidth: 125,
            isResizable: true,
          },
          {
            key: "StartTime",
            name: "Start Time",
            fieldName: "startTime",
            minWidth: 100,
            maxWidth: 125,
            isResizable: true,
          },
          {
            key: "EndTime",
            name: "End Time",
            fieldName: "endTime",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
          },
        ]}
        compact={true}
        items={Timeslots}
        selectionMode={SelectionMode.single}
        selection={_selection}
        selectionPreservedOnEmptyClick={true}
        onRenderRow={onRenderRow}
      />
    </Panel>
  );
};

export default TimeslotSelector;
