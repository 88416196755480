import { useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import {
  createWorkflow,
  editWorkflow,
  getWorkflow,
} from "../../services/assetServices";
import { useSetAtom } from "jotai";
import { successMessageAtom } from "../../atoms/messageBarAtoms";
import OtherInfo from "../../common/OtherInfo";

const Workflow = () => {
  const isSaved = useRef(false);
  const [workflowOtherInfo, setWorkflowOtherInfo] = useState<string>("{}");
  const setSuccessMessage = useSetAtom(successMessageAtom);

  return (
    <>
      <div>Workflow</div>
      <EditItem
        getAction={async (abortController, id) => {
          const workflow = await getWorkflow(abortController, id)
          setWorkflowOtherInfo(workflow.otherInfo);
          return workflow;
        }}
        newAction={async (abortController, newWorkflow) => {
          const newWorkflowId = await createWorkflow(abortController, {
            name: newWorkflow.name,
            url: newWorkflow.url,
            trigger: newWorkflow.trigger,
            purpose: newWorkflow.purpose,
            otherInfo: workflowOtherInfo,
          });
          setSuccessMessage(`Workflow with id: ${newWorkflowId} created.`);
        }}
        editAction={async (abortController, id, editedWorkflow) => {
          await editWorkflow(abortController, id, {
            name: editedWorkflow.name,
            url: editedWorkflow.url,
            trigger: editedWorkflow.trigger,
            purpose: editedWorkflow.purpose,
            otherInfo: workflowOtherInfo,
          });
          setSuccessMessage(`Workflow with id: ${id} successfully updated.`);
        }}
        isSaved={isSaved}
        back={"/workflows"}
        metadata={{
          fields: [
            {
              name: "name",
              fieldType: "String",
              label: "Name",
            },
            {
              name: "url",
              fieldType: "String",
              label: "Url",
            },
            {
              name: "trigger",
              fieldType: "Lookup",
              label: "Trigger",
              lookupList: "Triggers",
            },
            {
              name: "purpose",
              fieldType: "String",
              label: "Purpose",
            },
          ],
          lookups: [
            {
              name: "Triggers",
              values: [
                { key: "NoTrigger", value: "" },
                { key: "issue", value: "Issue", header: true },
                { key: "OnIssue", value: "On reporting an issue" },
                { key: "OnIssueUpdate", value: "On issue update" },
                { key: "workOrder", value: "WorkOrder", header: true },
                { key: "OnWorkOrder", value: "On creation of workorder" },
                { key: "OnWorkOrderUpdate", value: "On updating a workorder" },
                { key: "asset", value: "Asset", header: true },
                { key: "OnAsset", value: "On creation of asset" },
                { key: "OnAssetUpdate", value: "On updating an asset" },
                { key: "rent", value: "Rent", header: true },
                { key: "OnRentStart", value: "On starting a rent" },
                { key: "OnRentEnd", value: "On ending a rent" },
              ],
            },
          ],
          validations: [],
        }}
      >
        <OtherInfo
          metadata={{
            fields: [
              {
                name: "authentication",
                fieldType: "Lookup",
                label: "Authentication method",
                lookupList: "AuthenticationMethods",
              },
              {
                name: "userName",
                fieldType: "String",
                label: "Username",
              },
              {
                name: "password",
                fieldType: "Password",
                label: "Password",
              },
            ],
            lookups: [
              {
                name: "AuthenticationMethods",
                values: [{ key: "Basic", value: "Basic Authentication" }],
              },
            ],
            validations: [],
          }}
          otherInfo={workflowOtherInfo ?? "{}"}
          isSaved={isSaved}
          onOtherInfoChanged={(newValue: string) => {
            setWorkflowOtherInfo(JSON.stringify(newValue));
          }}
          hideTitle
        />
      </EditItem>
    </>
  );
};

export default Workflow;
