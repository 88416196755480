import {
  Link,
  Panel,
  PanelType,
  SearchBox,
  DetailsList,
  SelectionMode,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import {
  getActiveTrackingDevices,
  ITrackingDevice,
} from "../../services/assetServices";
import { useSetAtom } from "jotai";
import {
  isInProgressAtom,
  errorMessageAtom,
} from "../../atoms/messageBarAtoms";

export interface ITrackingDeviceSelectorProps {
  isOpen: boolean;
  hideTrackingDeviceSelector: any;
  onTrackingDeviceLinkClick: (assetId: number, assetCode: string, assetName: string, assetUuid: string) => void;
}

const TrackingDeviceSelectorPanel = (props: ITrackingDeviceSelectorProps) => {
  const [trackingDevices, setTrackingDevices] = useState<ITrackingDevice[]>([]);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        setTrackingDevices((await getActiveTrackingDevices(abortController)));
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
    return () => {};
  }, []);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideTrackingDeviceSelector}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText="Pick asset for selected issues"
    >
      <SearchBox
        placeholder="Search asset"
        onSearch={(newValue) => {
          const abortController = new AbortController();
          getActiveTrackingDevices(abortController, newValue).then(
            (trackingDevices: ITrackingDevice[]) => {
              setTrackingDevices(trackingDevices);
            }
          );
        }}
        onClear={() => setTrackingDevices([])}
      />
      <DetailsList
        columns={[
          {
            key: "Id",
            name: "Id",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            onRender: (item) => (
              <Link
                key={item.assetId}
                onClick={() => {
                  props.onTrackingDeviceLinkClick(item.id, item.code, item.name, item.uuid);
                  props.hideTrackingDeviceSelector();
                }}
              >
                {item.id}
              </Link>
            ),
          },
          {
            key: "Code",
            name: "Code",
            fieldName: "code",
            minWidth: 100,
            maxWidth: 125,
            isResizable: true,
          },
          {
            key: "type",
            name: "Type",
            fieldName: "type",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
          },
          {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
          },
        ]}
        compact={true}
        items={trackingDevices}
        selectionMode={SelectionMode.none}
      />
    </Panel>
  );
};

export default TrackingDeviceSelectorPanel;
