import { appRoles } from "../../authConfig";
import PagedList, { IPagedListRefType } from "../../common/PagedList";
import { deleteGroup, getGroups } from "../../services/assetServices";
import { useAtomValue, useSetAtom } from "jotai";
import { profileDataAtom } from "../../atoms/authAtoms";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { successMessageAtom } from "../../atoms/messageBarAtoms";

const Groups = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const [selectedGroupId, setSelectedGroupId] = useState<Number>();

  const pagedListRef = useRef<IPagedListRefType>(null);

  return (
    <PagedList
      selectedTab="Groups"
      ref={pagedListRef}
      columns={[{ key: "id" }, { key: "name", minWidth: 200 }]}
      getAction={getGroups}
      commandBarItems={[
        {
          key: "newGroup",
          text: "New",
          iconProps: { iconName: "Add" },
          onClick: () => navigate("/newGroup"),
          disabled: !profileData.roles.includes(appRoles.Admin),
        },
        {
          key: "editGroup",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: () => navigate(`/editGroup?id=${selectedGroupId}`),
          disabled:
            !profileData.roles.includes(appRoles.Admin) || !selectedGroupId,
        },
        {
          key: "deleteGroup",
          text: "Delete",
          iconProps: { iconName: "Delete" },
          onClick: () => {
            if (selectedGroupId) {
              const abortController = new AbortController();
              deleteGroup(abortController, Number(selectedGroupId)).then(() => {
                pagedListRef.current?.refresh();
                setSuccessMessage(`Group with id: ${selectedGroupId} deleted.`);
              });
            }
          },
          disabled:
            !profileData.roles.includes(appRoles.Admin) || !selectedGroupId,
        },
      ]}
      onSelectionChange={(selection: any) => {
        setSelectedGroupId(Number(selection.id));
      }}
      onRowDoubleClicked={(selection: any) =>
        navigate(`/editGroup?id=${selection.id}`)
      }
    ></PagedList>
  );
};

export default Groups;
