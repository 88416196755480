import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { APIProvider, Map as GoogleMap } from "@vis.gl/react-google-maps";
import { SelectionMode, Stack } from "@fluentui/react";
import {
  isInProgressAtom,
  errorMessageAtom,
} from "../../atoms/messageBarAtoms";
import {
  IEntityLocation,
  IPagedCollection,
} from "../../services/assetServices";
import PagedList from "../PagedList";
import { Polyline } from "../mapping/Polyline";
import { MarkerWithInfoWindow } from "./MarkerWithInfoWindow";

export interface IMapParams {
  Id: number | undefined;
  getLocations: (
    abortController: AbortController,
    rentId: number,
    pageSize: number,
    pageNo: number
  ) => Promise<IPagedCollection<IEntityLocation>>;
}

type TLineData = Array<{
  sourcePosition: Array<number>;
  targetPosition: Array<number>;
}>;

const Map = (params: IMapParams) => {
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const [locations, setLocations] = useState<IEntityLocation[]>([]);
  const [noLocations, setNoLocations] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<IEntityLocation>();
  const [lineData, setLineData] = useState<TLineData>([]);

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        if (!params.Id) {
          throw new Error("Id not provided");
        }
        const woLocations = await params.getLocations(
          abortController,
          params.Id,
          50,
          1
        );
        if (woLocations.items.length === 0) {
          setNoLocations(true);
        }
        setLocations(woLocations.items);
        setSelectedLocation(
          woLocations.items.length > 0 ? woLocations.items[0] : undefined
        );
        let lastLoc: IEntityLocation;
        const newLineData: TLineData = [];
        woLocations.items.forEach((loc: IEntityLocation) => {
          if (!lastLoc) {
            lastLoc = loc;
            return;
          }
          newLineData.push({
            sourcePosition: [lastLoc.longitude, lastLoc.latitude],
            targetPosition: [loc.longitude, loc.latitude],
          });
          lastLoc = loc;
        });
        setLineData(newLineData);
        console.log(JSON.stringify(newLineData));
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
  }, []);

  if (locations.length === 0 || lineData.length === 0) {
    return <div>{noLocations ? "No locations" : "Loading..."}</div>; // or some other loading indicator
  }
  //

  return (
    <Stack horizontal>
      <div
        style={{
          height: "70vh",
          width: "70%",
          position: "relative",
        }}
      >
        <APIProvider
          apiKey={`${process.env.REACT_APP_GOOGLE_MAP_APIKEY}`}
          libraries={["marker"]}
        >
          <GoogleMap
            defaultCenter={{
              lat: locations[0].latitude,
              lng: locations[0].longitude,
            }}
            defaultZoom={14}
            disableDefaultUI={true}
            style={{
              pointerEvents: "auto",
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 1,
            }}
            mapId={"7e43647fdfe8cf8f"}
          >
            {selectedLocation && (
              <MarkerWithInfoWindow
                position={{
                  lat: selectedLocation.latitude,
                  lng: selectedLocation.longitude,
                }}
                title={`: ${params.Id?.toString()}`}
              >
                {new Date(selectedLocation.time + "Z").toLocaleString()}
              </MarkerWithInfoWindow>
            )}
            <Polyline
              points={locations.map((location: IEntityLocation) => ({
                lat: location.latitude,
                lng: location.longitude,
              }))}
            />
          </GoogleMap>
        </APIProvider>
      </div>
      <div style={{ maxWidth: "30%" }}>
        <PagedList
          selectionMode={SelectionMode.single}
          onSelectionChange={(selection: any) => {
            setSelectedLocation({
              ...selection,
              time: new Date(selection.time).toISOString().replace("Z", ""),
            });
          }}
          selectedTab={""}
          columns={[
            { key: "time", name: "time", maxWidth: 150 },
            { key: "latitude", name: "latitude", maxWidth: 75 },
            { key: "longitude", name: "longitude", maxWidth: 75 },
          ]}
          commandBarItems={[]}
          detailsListWidth={360}
          hideCommandBar={true}
          getAction={async (
            abortController: AbortController,
            pageSize: number,
            pageNo: number
          ) => {
            const locations = await params.getLocations(
              abortController,
              params.Id ?? 0,
              pageSize,
              pageNo
            );
            return {
              ...locations,
              items: locations.items.map((t: IEntityLocation) => ({
                ...t,
                time: new Date(t.time + "Z").toLocaleString(),
              })),
            };
          }}
        />
      </div>
    </Stack>
  );
};

export default Map;
