import { ActionButton, IIconProps, Modal, Stack, Text } from "@fluentui/react";
import ModalIconButtonStyles from "./styles/ModalIconButtonStyles";
import {
  APIProvider,
  Map,
  MapMouseEvent,
  AdvancedMarker,
  Pin,
  Marker,
} from "@vis.gl/react-google-maps";
import { useState } from "react";

export interface ILocation {
  latitude: number;
  longitude: number;
}

const defaultLocation: ILocation = {
  latitude: -33.7613856,
  longitude: 150.9889242,
};

export class LocationHelper {
  static locationToString = (aLocation: ILocation): string => {
    return `lat: ${aLocation.latitude.toPrecision(
      9
    )}, lng: ${aLocation.longitude.toPrecision(9)}`;
  };
  static fromString = (locationStr: string): ILocation => {
    try {
      const parts = locationStr.split(",");
      const lat = Number.parseFloat(parts[0].replace("lat: ", ""));
      const lng = Number.parseFloat(parts[1].replace(" lng: ", ""));
      return { latitude: lat, longitude: lng };
    } catch (error) {
      return defaultLocation;
    }
  };
}

export interface IMapPickDialogParams {
  isModalOpen: boolean;
  hideModal: any;
  onMapClick: (newLocation: ILocation) => void;
  location?: ILocation;
}

const MapPickDialog = (params: IMapPickDialogParams) => {
  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const [newLocation, setNewLocation] = useState<ILocation>(
    params.location ?? defaultLocation
  );
  return (
    <Modal
      isOpen={params.isModalOpen}
      onDismiss={params.hideModal}
      isBlocking={false}
      styles={{
        scrollableContent: {
          height: 525,
          width: 550,
          position: "relative",
          padding: 10,
        },
      }}
    >
      <div>
        <Stack horizontalAlign="end" horizontal verticalAlign="center">
          <Text>
            Pick the location: {LocationHelper.locationToString(newLocation)}
          </Text>
          <Stack.Item grow>
            <span></span>
          </Stack.Item>
          <ActionButton
            iconProps={{ iconName: "CheckMark" }}
            onClick={() => {
              params.onMapClick(newLocation);
            }}
          >
            Ok
          </ActionButton>
          <ActionButton
            styles={ModalIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={params.hideModal}
          >
            Cancel
          </ActionButton>
        </Stack>
        <div
          style={{
            height: 450,
            width: "100%",
            position: "relative",
          }}
        >
          <APIProvider
            apiKey={`${process.env.REACT_APP_GOOGLE_MAP_APIKEY}`}
            libraries={["marker"]}
          >
            <Map
              defaultCenter={{
                lat: params.location?.latitude ?? newLocation.latitude,
                lng: params.location?.longitude ?? newLocation.longitude,
              }}
              defaultZoom={14}
              disableDefaultUI={true}
              style={{
                pointerEvents: "auto",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 1,
              }}
              mapId={"7e43647fdfe8cf8f"}
              onClick={(event: MapMouseEvent) => {
                setNewLocation({
                  latitude:
                    event.detail.latLng?.lat ?? defaultLocation.latitude,
                  longitude:
                    event.detail.latLng?.lng ?? defaultLocation.longitude,
                });
              }}
            >
              <Marker
                position={{
                  lat: params.location?.latitude ?? newLocation.latitude,
                  lng: params.location?.longitude ?? newLocation.longitude,
                }}
              ></Marker>
              {newLocation && newLocation !== params.location ? (
                <AdvancedMarker
                  position={{
                    lat: newLocation.latitude,
                    lng: newLocation.longitude,
                  }}
                >
                  <Pin
                    background={"#FBBC04"}
                    glyphColor={"#000"}
                    borderColor={"#000"}
                  />
                </AdvancedMarker>
              ) : null}
            </Map>
          </APIProvider>
        </div>
      </div>
    </Modal>
  );
};

export default MapPickDialog;
