import { Stack, Label, TextField, IconButton } from "@fluentui/react";
import {
  labelColumnStyle,
  valueColumnStyle,
} from "../../common/styles/FormsStyles";
import AssetSelectorPanel from "../issues/AssetSelectorPanel";
import { useBoolean } from "@fluentui/react-hooks";
import { IAsset } from "../../services/assetServices";

export interface IAssetSelectorParams {
  assetName?: string;
  assetId?: number;
  disabled: boolean;
  onSelected: (asset: IAsset) => void;
  errorMessage?: string;
}

const AssetSelector = (props: IAssetSelectorParams) => {
  const [
    isAssetSelectorOpen,
    { setTrue: showAssetSelector, setFalse: hideAssetSelector },
  ] = useBoolean(false);

  return (
    <Stack horizontal>
      <Label style={labelColumnStyle}>Asset</Label>
      <div
        style={{
          position: "relative",
          width: valueColumnStyle.width,
          marginLeft: 0,
        }}
      >
        <TextField
          style={valueColumnStyle}
          value={props?.assetName}
          readOnly
          onClick={showAssetSelector}
        />
        <IconButton
          iconProps={{ iconName: "Search" }}
          styles={{
            root: {
              position: "absolute",
              top: 2,
              right: 2,
              height: 28,
            },
          }}
          onClick={() => showAssetSelector()}
        />
      </div>
      <AssetSelectorPanel
        isOpen={isAssetSelectorOpen}
        hideAssetSelector={hideAssetSelector}
        onAssetLinkClick={(
          assetId: number,
          assetCode: string,
          assetName: string,
          assetUuid: string
        ) => {
          props.onSelected({
            id: assetId,
            code: assetCode,
            name: assetName,
            uuid: assetUuid,
            category: "",
            categoryId: 0,
          });
        }}
        //   onAssetLinkClick={props.onSelected}
      />
    </Stack>
  );
};

export default AssetSelector;
