import { appRoles } from "../../authConfig";
import PagedList from "../../common/PagedList";
import { getTenants } from "../../services/assetServices";
import { useAtomValue } from "jotai";
import { profileDataAtom } from "../../atoms/authAtoms";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Tenants = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();

  const [selectedTenantId, setSelectedTenantId] = useState<Number>();

  return (
    <>
      <PagedList
        selectedTab="Tenants"
        columns={[
          { key: "id", minWidth: 25 },
          { key: "name", minWidth: 100 },
          { key: "uuid", minWidth: 225 },
          { key: "description", minWidth: 200 },
        ]}
        getAction={getTenants}
        commandBarItems={[
          {
            key: "newTenant",
            text: "New",
            iconProps: { iconName: "Add" },
            onClick: () => navigate("/newTenant"),
            disabled: !profileData.roles.includes(appRoles.Admin),
          },
          {
            key: "editTenant",
            text: "Edit",
            iconProps: { iconName: "Edit" },
            onClick: () => navigate(`/editTenant?id=${selectedTenantId}`),
            disabled:
              !profileData.roles.includes(appRoles.Admin) || !selectedTenantId,
          },
        ]}
        onSelectionChange={(selection: any) => {
          setSelectedTenantId(Number(selection.id));
        }}
        onRowDoubleClicked={(selection: any) =>
          navigate(`/editTenant?id=${selection.id}`)
        }
      ></PagedList>
    </>
  );
};

export default Tenants;
