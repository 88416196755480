import { Stack, Label, TextField, IconButton } from "@fluentui/react";
import { labelColumnStyle, valueColumnStyle } from "../styles/FormsStyles";
import TrackingDeviceSelectorPanel from "./TrackingDeviceSelectorPanel";
import { useBoolean } from "@fluentui/react-hooks";
import { ITrackingDevice } from "../../services/assetServices";

export interface ITrackingDeviceSelectorParams {
  trackingDeviceName?: string;
  trackingDeviceId?: number;
  disabled: boolean;
  onSelected: (trackingDevice: ITrackingDevice) => void;
  errorMessage?: string;
}

const TrackingDeviceSelector = (props: ITrackingDeviceSelectorParams) => {
  const [
    isTrackingDeviceSelectorOpen,
    {
      setTrue: showTrackingDeviceSelector,
      setFalse: hideTrackingDeviceSelector,
    },
  ] = useBoolean(false);

  return (
    <Stack horizontal>
      <Label style={labelColumnStyle}>TrackingDevice</Label>
      <div
        style={{
          position: "relative",
          width: valueColumnStyle.width,
          marginLeft: 0,
        }}
      >
        <TextField
          style={valueColumnStyle}
          value={props?.trackingDeviceName}
          readOnly
          onClick={showTrackingDeviceSelector}
        />
        <IconButton
          iconProps={{ iconName: "Search" }}
          styles={{
            root: {
              position: "absolute",
              top: 2,
              right: 2,
              height: 28,
            },
          }}
          onClick={() => showTrackingDeviceSelector()}
        />
      </div>
      <TrackingDeviceSelectorPanel
        isOpen={isTrackingDeviceSelectorOpen}
        hideTrackingDeviceSelector={hideTrackingDeviceSelector}
        onTrackingDeviceLinkClick={(
          trackingDeviceId: number,
          trackingDeviceCode: string,
          trackingDeviceName: string,
          trackingDeviceType: string
        ) => {
          props.onSelected({
            id: trackingDeviceId,
            code: trackingDeviceCode,
            name: trackingDeviceName,
            isActive: true,
            type: trackingDeviceType,
          });
        }}
        //   onTrackingDeviceLinkClick={props.onSelected}
      />
    </Stack>
  );
};

export default TrackingDeviceSelector;
